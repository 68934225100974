import React, { Component } from 'react'
import Layout from '../components/layout/Layout'
import ContactModule from '../components/ContactModule'

class App extends Component {
  render() {
    return (
      <Layout backgroundImage galleryPage={false}>
        <ContactModule/>
      </Layout>
    )
  }
}

export default App
