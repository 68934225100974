import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

const ContactModule = () => {
  
  const postStyle = {
    color: 'black',
    backgroundColor: 'white',
    margin: '0 auto',
    borderRadius: '25px',
    fontSize: 'calc(1rem + 0.5vw)',
  }

  return (
    <Container style={postStyle} className="mt-5 p-5">
      <h1       style={{
                color: '#f0ad4e',
              }}>Formulaire de contact</h1>
      <Form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
      >
        <input type="hidden" name="bot-field" />{' '}
        <input type="hidden" name="form-name" value="contact" />
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Adresse email</Form.Label>
          <Form.Control type="email" name="email"placeholder="Votre adresse" />
        </Form.Group>
        <Form.Row>
          <Col>
            <Form.Group controlId="formGroupPrenom">
              <Form.Label>Prénom</Form.Label>
              <Form.Control name="prenom" placeholder="Prénom" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGroupNom">
              <Form.Label>Nom</Form.Label>
              <Form.Control name="nom" placeholder="Nom" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Group controlId="formGroupText">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            placeholder="Votre message"
            size="lg"
            name="message"
          />
        </Form.Group>
        <Button type="submit" variant="info">
          Envoyer
        </Button>
      </Form>
    </Container>
  )
}

export default ContactModule
